import './ResetPasswordBox.css'
import {useRef, useState} from "react";
import axios, {Axios, AxiosError} from "axios";
import {Link} from "react-router-dom";


function ResetPasswordBox() {
    const [passwordInputErrorInfo, setPasswordInputErrorInfo] = useState('');
    const [emailInputErrorInfo, setEmailInputErrorInfo] = useState('');
    const [showAllInputs, setShowAllInputs] = useState(false);
    const emailInputWrapperRef = useRef();
    const emailInputRef = useRef();
    const passwordInputRef = useRef();
    const formRef = useRef();

    const getEmailInputValue = (event) => {
        emailInputRef.current.className = '';
        setEmailInputErrorInfo("");
        emailInputRef.current.value = event.target.value;
    }
    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    const getPasswordInputValue = (event) => {
        passwordInputRef.current.className = '';
        setPasswordInputErrorInfo("");
        passwordInputRef.current.value = event.target.value;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
    //     const formData = {
    //         email: emailInputRef.current.value,
    //     };
    //     if (!validateEmail(formData.email)) {
    //         emailInputRef.current.className = 'error';
    //         setEmailInputErrorInfo("Gościu, to nawet mail nie jest..");
    //         return;
    //     }
    //     axios
    //         .post('http://localhost:3001/login', loginData, {
    //             withCredentials: true
    //         })
    //         .then((response) => {
    //             window.location.reload(false);
    //         })
    //         .catch((error) => {
    //
    //
    //             const errorInfo = error?.response?.data;
    //
    //             if (errorInfo?.code === -1) {
    //                 setEmailInputErrorInfo(errorInfo.info)
    //                 emailInputRef.current.className = 'error';
    //             } else if (errorInfo?.code === 0) {
    //                 setPasswordInputErrorInfo(errorInfo.info)
    //                 passwordInputRef.current.className = 'error';
    //             }
    //             else{
    //                 alert(error)
    //             }
    //
    //
    //         });
     }

    return (
        <div className={"reset-password-container"}>
            <div className={"reset-password"}>
                <div className={"tobenamed"}>
                    <button className={"reset-password_back-button"} onClick={() => {
                        setShowAllInputs(false);
                        emailInputWrapperRef.current.style.display = "initial";
                    }}>

                        {/*<svg enable-background="new 0 0 32 32" height="32px" id="Слой_1" version="1.1"*/}
                        {/*     viewBox="0 0 32 32" width="32px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg"*/}
                        {/*     xmlnsXlink="http://www.w3.org/1999/xlink"><path clip-rule="evenodd" d="M31.106,15H3.278l8.325-8.293  c0.391-0.391,0.391-1.024,0-1.414c-0.391-0.391-1.024-0.391-1.414,0l-9.9,9.899c-0.385,0.385-0.385,1.029,0,1.414l9.9,9.9  c0.391,0.391,1.024,0.391,1.414,0c0.391-0.391,0.391-1.024,0-1.414L3.278,17h27.828c0.552,0,1-0.448,1-1  C32.106,15.448,31.658,15,31.106,15z" fill="#121313" fill-rule="evenodd" id="Arrow_Back"/>*/}
                        {/*    <g/>*/}
                        {/*    <g/>*/}
                        {/*    <g/>*/}
                        {/*    <g/>*/}
                        {/*    <g/>*/}
                        {/*    <g/>*/}
                        {/*</svg>*/}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                             id="back-arrow">
                            <path fill="none" d="M0 0h24v24H0V0z" opacity=".87"></path>
                            <path
                                d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"></path>
                        </svg>
                    </button>
                    <h1>Resetowanie hasła</h1>
                </div>

                <form onSubmit={handleSubmit} ref={formRef}>
                    <div ref={emailInputWrapperRef} className={'reset-password__input-wrapper'}>
                        <input onChange={getEmailInputValue} ref={emailInputRef} type="text"
                               placeholder={"E-mail"}/>
                        <label>E-mail</label>
                        <p className={'input-error-info'}>{emailInputErrorInfo}</p>
                    </div>

                    {showAllInputs && <>
                        <div className={'reset-password__input-wrapper'}>
                            <input onChange={getEmailInputValue} type="text"
                                   placeholder={"Token autoryzacyjny"}/>
                            <label>Token autoryzacyjny</label>
                            <p className={'input-error-info'}>{emailInputErrorInfo}</p>
                        </div>
                        <div className={'reset-password__input-wrapper'}>
                            <input onChange={getPasswordInputValue} ref={passwordInputRef} type="password"
                                   placeholder={"Nowe hasło"}/>
                            <label>Nowe hasło</label>
                            <p className={'input-error-info'}>{passwordInputErrorInfo}</p>
                        </div>
                        <div className={'reset-password__input-wrapper'}>
                            <input onChange={getPasswordInputValue} ref={passwordInputRef} type="password"
                                   placeholder={"Potwierdź nowe hasło"}/>
                            <label>Potwierdź nowe hasło</label>
                            <p className={'input-error-info'}>{passwordInputErrorInfo}</p>
                        </div>
                    </>
                    }
                    <div className={'reset-password__input-wrapper'}>
                        <button onClick={() => {
                            if(validateEmail(emailInputRef.current.value)) {
                                setShowAllInputs(true);
                                emailInputWrapperRef.current.style.display="none";
                            }
                            else {
                                emailInputRef.current.className = 'error';
                                setEmailInputErrorInfo("Gościu, to nawet mail nie jest..");
                            }
                        }}>{showAllInputs ? "Resetuj hasło" : "Dalej"}</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ResetPasswordBox;
